<template>
  <v-footer :app="!isMobile"
            :fixed="false"
            :absolute="!isMobile"
            color="secondary"
            class="text-caption text-xl-body-1 py-0 theme--light">
    <v-row class="py-1 py-xl-2 my-1 px-10"
           justify="center"
           :style="{'text-align': isMobile ? 'center' : 'none'}"
           align="center">
      <v-col cols="12"
             md="4"
             class="ma-0 pa-0"
             style="text-align: center;">
        <a href="https://www.gunb.gov.pl/" target="_blank">
          <img :height="isMobile ? 120 : 140"
               :style="isMobile ? 'width: 100%; max-width: 320px;' : 'width: auto; max-width: 100%;'"
               :src="logoSrc" />
        </a>
      </v-col>
      <v-col cols="12"
             class="pa-0"
             md="8">
        <v-row align="center" >
          <v-col cols="6"
                 md="2">
            <p class="mb-0">
              <router-link class="primary-text" :to="{ name: 'PrivacyPolicy' }">
                Polityka prywatności
              </router-link>
            </p>
          </v-col>
          <v-col cols="6"
                 md="2">
            <p class="mb-0">
              <router-link class="primary-text" :to="{ name: 'AccessibilityDeclaration' }">
                Deklaracja dostępności
              </router-link>
            </p>
          </v-col>
          <v-col cols="12"
                 md="3">
            <p class="mb-0">Masz pytania? Napisz na:</p>
            <p class="mb-0">
              <a class="primary-txt--text" href="mailto:e-budownictwo@gunb.gov.pl">
                e-budownictwo@gunb.gov.pl
              </a>
            </p>
          </v-col>
          <v-col cols="6"
                 md="2">
            <p class="mb-0">System wykonał:</p>
            <p class="mb-0">
              <a class="primary-txt--text" target="_blank" href="https://smallgis.pl/">
                SmallGIS Sp z o.o.
              </a>
            </p>
          </v-col>
          <v-col cols="6"
                 md="3"
                 style="text-align: center;">
            <a href="https://www.linkedin.com/company/gunb" target="_blank" style="margin-right: 5px;">
              <img :height="isMobile ? 38 : 50" src="/img/linkedin.svg"/>
            </a>
            <a href="https://twitter.com/GUNB_Urzad" target="_blank" style="margin-right: 5px;">
              <img :height="isMobile ? 38 : 50" src="/img/x.svg"/>
            </a>
            <a href="https://www.youtube.com/channel/UCE6OMf81seJQ1NCf107U_Eg" target="_blank">
              <img :height="isMobile ? 38 : 50" src="/img/youtube.svg"/>
            </a>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "TheFooter",
  computed: {
    logoSrc() {
      return this.$vuetify.theme.dark
          ? "/img/30lat_GUNB_logo_ekran_bez_tla_poziom_kolor negatyw.png"
          : "/img/30lat_GUNB_logo_ekran_poziom_bialy.png";
    },
    darkModeClass() {
      return this.$vuetify.theme.dark ? "" : "primary-txt--text";
    },
  },
  methods: {
    darkMode() {
      const isDark = localStorage.getItem("edb-dark");
      if (isDark == null || isDark == "false") {
        localStorage.setItem("edb-dark", "true");
      } else {
        localStorage.setItem("edb-dark", "false");
      }
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
  mounted() {
    const isDark = localStorage.getItem("edb-dark");
    if (
        isDark == "true" ||
        (window.matchMedia &&
            window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      this.$vuetify.theme.dark = true;
    }
  },
};
</script>

<style>
.primary-text {
  text-decoration: none;
}

.primary-text:hover {
  font-weight: bold;
  text-decoration: none;
}
</style>
